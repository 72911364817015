<template>
  <div id="indextitle">
    <div class="indextitle-left">
      <img :src="imgurl" alt />
      <slot></slot>
    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    imgurl: { default: "" },
  },
  data() {
    return {

    }
  }
};
</script>

<style scoped>
#indextitle {
  width: 100%;
  padding: 20px 0px 0px 0px;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.indextitle-left {
  display: flex;
  width: 100%;
  font-size: 20px;
}
.indextitle-right {
  font-size: 14px;
}
.indextitle-left img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}
</style>