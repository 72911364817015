<!--横向导航栏组件-->
<template>
  <div>
    <a-menu
      :selectedKeys="$route.name?[$route.name]:[]"
      @select="handleclick"
      mode="horizontal"
    >
      <template v-for="item in list">
        <a-menu-item v-if="!item.children.length > 0" :key="item.code">
          <span>{{ item.name }}</span>
        </a-menu-item>
        <sub-menu v-else :key="item.code" :menu-info="item" />
      </template>
    </a-menu>
  </div>
</template>

<script>
import { Menu } from "ant-design-vue";
const SubMenu = {
  template: `
      <a-sub-menu :key="menuInfo.code" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <span>{{ menuInfo.name }}</span>
        </span>
        <template v-for="item in menuInfo.children">
          <a-menu-item v-if="!item.children.length > 0" :key="item.code">
            <span>{{ item.name }}</span>
          </a-menu-item>
          <sub-menu v-else :key="item.code" :menu-info="item" />
        </template>
      </a-sub-menu>
    `,
  name: "SubMenu",
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
export default {
  props: {
    list: {
      type: Array,
    },
  },
  components: {
    "sub-menu": SubMenu,
  },
  data() {
    return {};
  },
  methods: {
    handleclick(e) {
      this.$router.push({
        name: `${e.key}`,
        // 添加serviceType传参，修改设备页跳转至其他分页再次跳转回设备页导致serviceType参数丢失，重新加载设备页因参数丢失请求失败
        // 2023-03-22 14:51 lht
        query: { id: this.$route.query.id, Code: this.$route.query.Code, serviceType:this.$route.query.serviceType }
      })
    },
  },
};
</script>

<style scoped>
div /deep/.ant-menu-item:hover{
  border-bottom: 2px solid transparent;
}
div /deep/.ant-menu-submenu:hover{
  border-bottom: 2px solid transparent;
}
</style>